import { gql } from '@apollo/client';

export const SIGN_IN_QUERY = gql`
  query signIn($input: SignInInput!) {
    signIn(input: $input)
  }
`;

export const APPLICATIONS_QUERY = gql`
  query applications($filter: FilterInput!) {
    applications(filter: $filter) {
      items {
        createdAt
        id
        name
        contact
        message
      }
      count
    }
  }
`;

export const APPLICATION_QUERY = gql`
  query application($id: Int!) {
    application(id: $id) {
      createdAt
      id
      name
      contact
      message
    }
  }
`;
