import { Wrap } from './Application.styled';
import { BackBtn, DetailRow, RequestHandler } from '../../components';
import { Box, Typography } from '@mui/material';
import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import { useApplicationQuery } from '../../hooks';
import { useParams } from 'react-router-dom';
import { APPLICATION_FIELD_OPTIONS } from '../../constants/constants';
import { getDefaultDateFormat } from '../../utils';

export const Application = () => {
  const { id } = useParams();

  console.log({ id });

  const { data, error, loading } = useApplicationQuery({
    variables: {
      id: +id,
    },
  });

  const application = data?.application;

  return (
    <RequestHandler error={error} loading={loading}>
      <Wrap>
        <BackBtn sx={{ marginBottom: '30px' }} />

        <Typography
          component='h2'
          mb={'20px'}
          fontSize={fontSizes.ml}
          fontWeight={fontWeights.BOLD}
        >
          Детали заявки:
        </Typography>
        <Box>
          {APPLICATION_FIELD_OPTIONS.map((option) => {
            const fieldValue =
              option.id === 'createdAt' && application?.[option.id]
                ? getDefaultDateFormat(application?.[option.id])
                : application?.[option.id];
            const fieldLabel = option.label;
            if (!fieldValue) return null;
            return (
              <DetailRow
                key={option.id}
                title={fieldLabel}
                description={fieldValue}
              />
            );
          })}
        </Box>
      </Wrap>
    </RequestHandler>
  );
};
