import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  Toolbar,
  Typography,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NAVIGATIONS } from '../../../constants/constants';

import { useAuthContext } from '../../../context';
import palette from '../../../theme/palette';
import { AppBar, Drawer, DrawerHeader, ListItem, ListItemText } from './styles';

export const MainLayout = ({ title, children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  const { onLogout } = useAuthContext();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' mr={'auto'}>
            Панель управления
          </Typography>

          <Box ml={'20px'}>
            <IconButton aria-label='add to shopping cart' onClick={onLogout}>
              <LogoutIcon sx={{ color: palette.white }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {NAVIGATIONS.map(({ title, route, Icon }, index) => {
            return (
              <ListItem
                onClick={() => {
                  navigate(route);
                }}
                isactive={location.pathname === route ? 'true' : 'false'}
                button
                key={index}
              >
                <ListItemIcon sx={{ position: 'relative' }}>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={title}
                  isactive={location.pathname === route ? 'true' : 'false'}
                />
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        <Typography variant='h2' mb={'30px'}>
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
