import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import PublicIcon from '@mui/icons-material/Public';

import { Applications, Login } from '../screens';
import { Application } from '../screens/Application/Application';

export const PATHS = {
  applications: '/applications',
  application: '/applications/:id',

  login: '/',
};

export const ROUTES = [
  {
    path: PATHS.login,
    component: Login,
    auth: false,
  },
  {
    path: PATHS.applications,
    component: Applications,
    auth: true,
  },
  {
    path: PATHS.application,
    component: Application,
    auth: true,
  },
];

export const NAVIGATIONS = [
  {
    title: 'Заявки',
    route: PATHS.applications,
    Icon: AppsOutageIcon,
  },
];

// tables constants
export const TABLE_LIMITS_ARRAY_OPTIONS = [5, 10, 15];

export const SIMPLE_APPLICATION_OPTIONS = [
  { id: 'idNumber', label: 'Номер заявки', minWidth: 100 },
  { id: 'name', label: 'Имя', minWidth: 100 },
  { id: 'contact', label: 'Контакт', minWidth: 100 },
  { id: 'date', label: 'Дата', minWidth: 100 },
  { id: 'control', label: 'Контроль', minWidth: 100 },
];

export const APPLICATION_FIELD_OPTIONS = [
  { id: 'id', label: 'Номер заявки' },
  { id: 'name', label: 'Имя' },
  { id: 'contact', label: 'Контакт' },
  { id: 'createdAt', label: 'Дата заявки' },
  { id: 'message', label: 'Сообщение' },
];

export const MODAL_NAME = {
  conditionModal: 'ConditionModal',
};

export const TABLE_COLUMN_VARIANTS = {
  control: 'control',
  image: 'image',
};
