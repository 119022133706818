import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableList from '../../components/TableList/TableList';
import {
  MODAL_NAME,
  PATHS,
  SIMPLE_APPLICATION_OPTIONS,
} from '../../constants/constants';

import { Wrap } from './Applications.styled';
import { useModalContext } from '../../context';
import {
  useApplicationsQuery,
  useRemoveApplicationMutation,
} from '../../hooks';
import { getDefaultDateFormat } from '../../utils';
import { toast } from 'react-toastify';

const createData = (application) => {
  const idNumber = `#${application.id}`;

  const date = getDefaultDateFormat(application.createdAt);

  return { ...application, idNumber, date };
};

export const Applications = () => {
  const { onOpenModal } = useModalContext();
  const navigate = useNavigate();

  const [applicationsQuery, { loading, data }] = useApplicationsQuery();
  const [removeApplicationMutation] = useRemoveApplicationMutation({
    onCompleted: () => {
      toast('Заявка успешно удалена', {
        type: 'success',
      });
      getApplications();
    },
  });

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const handleRemove = (id) => {
    removeApplicationMutation({
      variables: {
        id: +id,
      },
    });
  };

  const getApplications = () => {
    applicationsQuery({
      variables: {
        filter: {
          limit,
          offset: page * limit,
          status: 'NEW',
        },
      },
    });
  };

  const rows =
    data?.applications?.items?.map((application) => createData(application)) ||
    [];
  const count = data?.applications?.count || 0;

  useEffect(() => {
    getApplications();
  }, [limit, page]);

  useEffect(() => {
    getApplications();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(+event.target.value);
  };

  const handleDelete = (row) => {
    onOpenModal(MODAL_NAME.conditionModal, {
      id: row?.id,
      handelSubmit: () => handleRemove(row.id),
      title: `Удалить заявку ${row.idNumber}?`,
    });
  };

  const handleOpen = (row) => {
    navigate(`${PATHS.applications}/${row.id}`);
  };

  return (
    <Wrap>
      <TableList
        name={'Список заявок'}
        isLoading={loading}
        count={count}
        limit={limit}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        headCells={SIMPLE_APPLICATION_OPTIONS}
        rows={rows}
        hasFilter
        handleDelete={handleDelete}
        handleOpen={handleOpen}
      />
    </Wrap>
  );
};
